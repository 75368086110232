<template>
    <div>
        <b-card
            no-body
        >
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{i18nT(`Task groups`)}}</h1>
                            <b-button
                                :to="{ name: 'create-task-group'}"
                                class="float-right"
                                variant="primary"
                                v-if="iCan('tasks', 'write')"
                            >
                                <feather-icon icon="PlusCircleIcon" />
                                {{i18nT(`Add new`)}}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{i18nT(`Showing`)}} {{ dataMeta.from }} {{i18nT(`to`)}} {{ dataMeta.to }} {{i18nT(`of`)}} {{ dataMeta.of }} {{i18nT(`entries`)}}</span>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-if="selectedItems.length > 0 && iCan('tasks', 'write')"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                class="d-inline ml-1"
                                size="md"
                                text="Actions"
                                variant="outline-secondary"
                            >
                                <b-dropdown-item @click="onBulkCopy(data)" >
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon color="red" icon="TrashIcon"/>
                                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                            >
                                <template v-slot:button-content>
                                    <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                                </template>
                                <b-dropdown-item @click="onExportExcel()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onExportCsv()">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                                </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div class="input-group input-group-merge" role="group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon" />
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            class="d-inline-block"
                                            type="text"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refTaskListTable"
                        v-model="currentItems"
                        :empty-html="emptyHtml"
                        :fields="computedColumns"
                        :items="fetchTaskgroups"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        primary-key="id"
                        responsive
                        show-empty
                        @refreshed="onTableRefresh"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <b-link
                                :to="{ name: 'edit-task-group', params: { id: data.item.Id } }"
                                class="font-weight-bold"
                            >
                                {{ data.item.Label }}
                            </b-link>
                        </template>

                        <template #cell(desription)="data">
                            {{data.item.Description || ''}}
                        </template>

                        <template #cell(created)="data">
                            {{data.item.CreatedAt | date}}
                        </template>

                        <template #cell(count)="data">
                            {{data.item.TasksCount}}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                :right="$store.state.appConfig.isRTL"
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                                v-if="iCan('tasks', 'write')"
                            >

                                <template #button-content>
                                    <feather-icon
                                        class="align-middle text-body"
                                        icon="MoreVerticalIcon"
                                        size="16"
                                    />
                                </template>
                                <b-dropdown-item :to="{ name: 'edit-task-group', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon" />
                                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon color="red" icon="TrashIcon" />
                                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs v-if="false" align="center" pills>
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="ListIcon" size="24" style="margin: 0;"/>
                        </template>

                    </b-tab>

                    <b-tab>
                        <template #title style="text-align: center">
                            <feather-icon icon="GridIcon" size="24" style="margin: 0;"/>
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    v-for="taskGroup in taskGroups"
                                    :key="taskGroup.Id"
                                    cols="3"
                                >
                                    <b-card
                                        style="border: 1px solid rgba(0, 0, 0, 0.07);"
                                    >
                                        <b-card-body class="p-0">
                                            <p>{{taskGroup.TaskType}}</p>
                                            <h3 style="height: 50px;">{{taskGroup.Label}}</h3>
                                            <p style="height: 45px; overflow: hidden;" v-html="taskGroup.Description"></p>
                                            <b-list-group class="list-group-messages" style="height: 140px">
                                                <b-list-group-item v-if="taskGroup.CreatedAt" class="p-0 border-0 line-item">
                                                    <feather-icon
                                                        class="mr-75"
                                                        icon="CalendarIcon"
                                                        size="18"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Created {{taskGroup.CreatedAt | date}}</span>
                                                </b-list-group-item>
                                                <b-list-group-item v-if="taskGroup.UpdatedAt" class="p-0 border-0 line-item">
                                                    <feather-icon
                                                        class="mr-75"
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Last update {{taskGroup.UpdatedAt | date}}</span>
                                                </b-list-group-item>
                                                <b-list-group-item v-if="taskGroup.TasksCount" class="p-0 border-0 line-item">
                                                    <feather-icon
                                                        class="mr-75"
                                                        icon="AlignLeftIcon"
                                                        size="18"
                                                    />
                                                    <span class="align-text-bottom line-height-1">{{taskGroup.TasksCount}} Tasks</span>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                no-caret
                                                toggle-class="p-0"
                                                variant="link"
                                                v-if="iCan('tasks', 'write')"
                                            >
                                                <template #button-content>
                                                    <b-button
                                                    >
                                                        {{i18nT(`Actions`)}}
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item>
                                                    <feather-icon icon="BookIcon" />
                                                    <span class="align-middle ml-50">{{i18nT(`View`)}}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy(task)">
                                                    <feather-icon icon="CopyIcon" />
                                                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item>
                                                    <feather-icon icon="EditIcon" />
                                                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item>
                                                    <feather-icon color="red" icon="TrashIcon" />
                                                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            cols="12"
                            sm="6"
                        >
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                            >
                                {{ i18nT(`All`)}}
                            </b-form-checkbox>
                            <span class="text-muted">{{i18nT(`Showing`)}} {{ dataMeta.from }} {{i18nT(`to`)}} {{ dataMeta.to }} {{i18nT(`of`)}} {{ dataMeta.of }} {{i18nT(`entries`)}}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            cols="12"
                            sm="6"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalTaskgroups"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BLink,
    BFormGroup,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTaskGroupList from './useTaskGroupList'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import useAuth from "@/auth/useAuth";

export default {
    components: {
        BRow,
        // BFormSelect,
        BCol,
        BFormGroup,
        BCard,
        BFormInput,
        BTable,
        // BMedia,
        // BAvatar,
        // BLink,
        // BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BTooltip,
        BTabs,
        BTab,
        BButton,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        BLink,
        vSelect,
        BFormCheckbox
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            avalableFields: [
              {label: this.i18nT(`Description`), key: 'desription', visible: true},
              {label: this.i18nT(`Created`), key: 'created', visible: true},
              ],
            softwarePath: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    created() {
        let storePreferences = useAuth.getColumnPreferences('taskGroups')
        if(storePreferences) {
          this.avalableFields = storePreferences
        }

        this.fetchTaskgroups(null, this.determineVisibility);

    },
    methods: {
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        getAvatarName(name) {
            if(name) {
                return name
                    .split(' ')
                    .map((namePart) => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase();
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(task) {
            this.$http.post(
                `tasks/copyTaskGroup?task_group_id=${task.item.Id}`
            ).then(() => {
                this.refetchData()
            })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `tasks/taskGroups?id=${task.item.Id}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http.post(
                `tasks/copyTaskGroup?task_group_id=${this.selectedItems.join(",")}`
            ).then(() => {
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `tasks/taskGroups?id=${this.selectedItems.join(",")}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false
                        this.refetchData()
                    })
                }
            })
        },
        onSelectAll(val) {
            if(val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {

        },
        onExportCsv() {

        }
    },
    setup() {
        /*
      const statusOptions = [
        'Downloaded',
        'Draft',
        'Paid',
        'Partial Payment',
        'Past Due',
      ]
  */
        const {
            fetchTaskgroups,
            tableColumns,
            perPage,
            currentPage,
            totalTaskgroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            taskGroups,
            refetchData
        } = useTaskGroupList()

        return {
            fetchTaskgroups,
            tableColumns,
            perPage,
            currentPage,
            totalTaskgroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            avatarText,
            taskGroups,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your tasks groups. No tasks have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/"+ this.softwarePath +"/create-task-group\"'>" + this.i18nT(`Create task`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('taskGroups', this.avalableFields);

            return col
      },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
